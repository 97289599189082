<template>
  <span class="">
    {{$moment(time).fromNow()}}
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import moment from "moment";

export default {
  name: "onlinesince",
  components: {},
  props: {
    //time: { default: moment().format(), type: String },
    dbPathField: { default: null, type: String },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      time: moment().format(),
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    if(self.dbPathField != null) {
      rdb.ref(self.dbPathField).on('value',function(snapshot) {
        var obj = snapshot.val();
        if(obj != null) {
          self.time = obj.last;
          //console.log("self.time",self.time);
        }
      });
    }
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
