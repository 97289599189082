<template>
  <div class="row">
    <div class="col-12 col-lg-12 mb-0">
      <v-card class="mb-0 pb-0 mt-0 pt-2" outlined>
        <v-card-text class="mb-0 pb-0">
          <v-row class="mb-0 pb-0">
            <v-col class="pt-0 pb-0" cols="12" md="4" lg="5">
              <filtro @changesel="changeFiltro"/>
            </v-col>
            <v-col class="py-0 my-0" cols="12" md="4" lg="5">
              <selectusers @changesel="changeSelectUsers"/>
            </v-col>
            <v-col class="py-0 mb-0" cols="12" md="4" lg="2">
              <p class="fs-10pt pt-2 pb-0 mb-0 fw-400 f-yanone text-left">
                <v-btn class="ps-1 ms-1 f-roboto fs-7pt" :color="cor.active.S" x-small @click="$refs.formusuarioref.show(null)">
                  <i class="btn-icon-left" v-i:duo#plus-circle#16></i>
                  novo colaborador
                </v-btn>
              </p>
            </v-col>
          </v-row>
          <!-- <p><VueObjectView v-model="value" :key="refreshKey"/></p> -->
          <v-row class="mt-n4 pt-0">
            <v-col cols="12 text-right">
              <v-btn class="m-0 p-0 text-nonecase" color="" text x-small @click="exportar">
                <i class="btn-icon-left" v-i:duo#download#16></i>
                exportar
              </v-btn>
            </v-col>
          </v-row>

          <div v-if="loading" class="mt-4">
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
          </div>

          <v-alert v-if="!loading && usuarios.length == 0" class="mt-4" outlined color="grey" type="warning" prominent border="left">
            <p class="" style="color: black;">
              Nenhum resultado encontrado.
            </p>
          </v-alert>

          <hr class="mt-1 mb-2">

          <v-list two-line>
            <div class="" v-for="(user,key,index) in usuariosFiltrados" v-if="index < 30">
              <v-list-item class="mb-0 pb-0 mt-n2">
                <v-list-item-avatar size="52" class="mt-0">
                  <v-avatar size="52">
                    <img :src="user.urlFoto" class="rounded-circle" alt="" style="">
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content class="text-left">
                  <v-list-item-title class="">
                    <p class="mb-0 fs-10pt f-roboto">
                      {{user.nome}}
                      <span class="text-muted fs-10pt f-roboto">
                        <b-icon icon="envelope-fill" class="ms-2 me-1"></b-icon>
                        {{user.email}}
                      </span>
                      <span class="text-muted fs-10pt f-lato">
                        <b-icon icon="telephone-fill" class="ms-2 me-1"></b-icon>
                        {{user.telefone}}
                      </span>
                      <span class="text-muted fs-10pt f-lato">
                        <span class="ms-2 me-1 fw-600 fs-9pt">CPF:</span>
                        <span>{{user.cpf}}</span>
                      </span>
                    </p>
                    <p class="m-0 pt-1 fs-8pt f-roboto" v-if="user.emails != null">
                      <span class="me-1 fs-8pt f-roboto" v-for="item in user.emails" :key="item">
                        {{item}}
                      </span>
                    </p>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <p class="fs-10pt f-roboto mt-1">
                      {{user.cargo}}
                      <!-- <tagsarea :siglas="item.Areas"/> -->
                      <span class="badge ms-2 fs-9pt f-roboto fw-400 text-dark" style="backgroundColor: #f0f0f0; vertical-align: bottom;">
                        {{user.ies}} - {{user.regional}}
                      </span>
                      <v-btn v-if="isUserAdmin" @click="acessarComo(user)" x-small outlined :color="cor.active.I" class="ms-4 px-1 text-nonecase">
                        <i v-i:ic#login#16 class="btn-icon-left"></i>
                        <span class="ms-0">Acessar como</span>
                      </v-btn>
                    </p>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon  class="px-0 mx-0">
                  <v-btn class="px-0 mx-0" :color="cor.active.D" small icon @click="$refs.promptdialogref.show(user)">
                    <i class="btn-icon-left ps-1" v-i:duo#trash#20></i>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-icon  class="px-0 mx-0 ms-1" @click="formShow(user)">
                  <v-btn class="px-0 mx-0" :color="cor.active.I" small icon>
                    <i class="btn-icon-left ps-1" v-i:duo#pencil#20></i>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
              <v-divider inset class="mt-n4"></v-divider>
            </div>
          </v-list>

        </v-card-text>
      </v-card>
    </div>

    <formusuario ref="formusuarioref" @update="formUpdate"/>
    <promptdialog ref="promptdialogref" @confirm="confirmaRemover" />

  </div>

</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import VueObjectView from 'vue-object-view';
import formusuario from "./formUsuario.vue";
import promptdialog from "./promptdialog.vue";
import filtro from "./filtro.vue";
import selectusers from "./selectusers.vue";
import XLSX from 'xlsx';
import moment from 'moment';

export default {
  name: "colaboradores",
  components: { formusuario, promptdialog, filtro, selectusers },
  data(){
    return {
      loading: false,
      usuarios: [],
      usuarioParaAtualizar: {},
      usuariosFiltrados: [],
      setores: [],
      setorSel: [],
      equipes: [],
      equipeSel: [],
      nomesSel: [],
      cargos: [
        { id: 'Educador(a)', nome: 'Educador(a)' },
        { id: 'Professor(a)', nome: 'Professor(a)' },
        { id: "Gestor(a) de Área", nome: "Gestor(a) de Área" },
        { id: 'Assessor(a) de Área', nome: 'Assessor(a) de Área' },
        { id: 'Coordenador(a) Regional', nome: 'Coordenador(a) Regional' },
        { id: 'Coordenador(a) de Área', nome: 'Coordenador(a) de Área' },
        { id: 'Coordenador(a) de Cursos', nome: 'Coordenador(a) de Cursos' }
      ],
      cargoSel: [],
      refreshKey: 0,
      filtros: [],
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("usuariosview Mounted");
    this.loading = true;
    rdb.ref('/usuarios/pessoas').on('value', function(snapshot) {
      self.usuarios = snapshot.val();
      self.usuariosFiltrados = self.usuarios;
      self.filtrar();
      self.loading = false;
    });
    console.log(self.usuarios);
    rdb.ref('/usuarios/setores').on('value', function(snapshot) {
      self.setores = snapshotValToArray(snapshot.val());
      console.log(self.setores);
    });
    rdb.ref('/usuarios/equipes').on('value', function(snapshot) {
      self.equipes = snapshotValToArray(snapshot.val());
      console.log(self.equipes);
    });
    //console.log(self.userLog);
  },

  methods:{

    normalizer(node) {
      return {
        id: node.id,
        label: node.nome,
      }
    },

    changeFiltro(dados) {
      console.log("changeFiltro",dados);
      this.filtros = dados;
      this.filtrar();
    },

    filtrar() {
      var self = this;
      console.log("filtrar");
      this.usuariosFiltrados = this.usuarios;
      this.filtrarNome();
      this.filtrarSetor();
      this.filtrarEquipe();
      this.filtrarCargo();
      console.log("this.usuariosFiltrados",self.usuariosFiltrados);
    },

    filtrarNome() {
      var self = this;
      console.log("filtrarNome");
      if(this.nomesSel.length == 0) {
        return;
      }
      var usuarios = {};
      for(var key in self.usuariosFiltrados) {
        if(this.nomesSel.indexOf(key) != -1) {
          usuarios[key] = self.usuariosFiltrados[key];
        }
      }
      self.usuariosFiltrados = usuarios;
    },

    filtrarSetor() {
      var self = this;
      console.log("filtrarSetor");
      if(this.setorSel.length == 0) {
        return;
      }
      var usuarios = {};
      for(var key in self.usuariosFiltrados) {
        for(var keySetor in self.usuariosFiltrados[key].setores) {
          if(self.setorSel.indexOf(keySetor) != -1) {
            usuarios[key] = self.usuariosFiltrados[key];
          }
        }
      }
      self.usuariosFiltrados = usuarios;
    },

    filtrarEquipe() {
      var self = this;
      console.log("filtrarEquipe");
      if(this.equipeSel.length == 0) {
        return;
      }
      var usuarios = {};
      for(var key in self.usuariosFiltrados) {
        for(var keySetor in self.usuariosFiltrados[key].equipes) {
          if(self.equipeSel.indexOf(keySetor) != -1) {
            usuarios[key] = self.usuariosFiltrados[key];
          }
        }
      }
      self.usuariosFiltrados = usuarios;
    },

    filtrarCargo() {
      var self = this;
      console.log("filtrarCargo", self.filtros);
      if(this.filtros.length == 0) {
        return;
      }
      var usuarios = {};
      for(var key in self.usuariosFiltrados) {
        for(var keyCargo in self.filtros) {
          if(self.usuariosFiltrados[key].cargo == self.filtros[keyCargo]) {
            usuarios[key] = self.usuariosFiltrados[key];
          }
        }
      }
      self.usuariosFiltrados = usuarios;
    },

    formShow(item) {
      this.usuarioParaAtualizar = item;
      const usuario = clone2(item);
      this.$refs.formusuarioref.show(usuario);
    },

    formUpdate(dados) {
      console.log("dados",dados);
      var updates = {};
      var elem = clone2(dados.formData);
      if(dados.tipo == "Atualizar") {
        updates["/usuarios/pessoas/"+elem.id] = elem;
        console.log("this.usuarioParaAtualizar",this.usuarioParaAtualizar);
        for(var key in this.usuarioParaAtualizar.equipes) {
          var achei = false;
          for(var key2 in elem.equipes) {
            if(key == key2) achei = true;
          }
          if(!achei) {
            updates["/usuarios/equipes/"+key+"/pessoas/"+elem.id] = null;
          }
        }
      } else {
        var newKey = rdb.ref('/usuarios/pessoas/').push().key;
        elem.id = newKey;
        updates["/usuarios/pessoas/"+newKey] = elem;
      }
      for(var eqKey in elem.equipes) {
        updates["/usuarios/equipes/"+eqKey+"/pessoas/"+elem.id] = { id: elem.id, nome: elem.nome, email: elem.email };
      }
      console.log(updates);
      rdb.ref().update(updates);
      this.toastS("Dados cadastrados com sucesso!");
      console.log("agora já elvis!");
    },

    confirmaRemover(item) {
      console.log("confirmaRemover",item);
      var elem = clone2(item);
      console.log("confirmaRemover",elem);
      var updates = {};
      for(var key in elem.equipes) {
        updates["/usuarios/equipes/"+key+"/pessoas/"+elem.id] = null;
      }
      updates["/usuarios/pessoas/"+elem.id] = null;
      console.log(updates);
      rdb.ref().update(updates);
      this.toastS("Item removido com sucesso!");
      console.log("agora já elvis!");
    },

    acessarComo(user) {
      var self = this;
      console.log("acessarComo(user)",user);
      self.$store.commit("setLastUserAdmin", self.userLog);
      self.$store.commit("login", { "self": self , "user": user });
      eventBus.$emit("atualizaUsuario");
    },

    changeSelectUsers(dados) {
      console.log("changeSelectUsers",dados);
      this.nomesSel = dados;
      this.filtrar();
    },

    exportar() {
      console.log("exportar");
      //console.log("this.usuarios",this.usuarios);
      var usuarios = [];
      for(var key in this.usuarios) {
        var usuario = clone2(this.usuarios[key]);
        usuario.areasIC = null;
        delete usuario.areasIC;
        usuario.formacaoArea = usuario.formacao.area;
        usuario.titulacao = usuario.formacao.titulacao;
        delete usuario.admin;
        delete usuario.formacao;
        delete usuario.senha;
        delete usuario.urlCapa;
        delete usuario.urlFoto;
        delete usuario.setores;
        delete usuario.equipes;
        delete usuario.equipeAvaliacao;
        delete usuario.minhasUCs;
        usuarios.push(usuario);
      }
      console.log("usuarios",usuarios);
      const fileName = "appIntegra-usuarios-"+moment().format()+".xlsx";
      const ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(usuarios);
      const wb = XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Usuarios');
      XLSX.writeFile(wb, fileName);

    }


  }
}
</script>

<style scoped>

</style>
