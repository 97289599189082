<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined class="mt-3">
        <p class="my-2 mx-2">
          Atualizar Ofertas de UCs - 2021.2
          <v-btn class="ms-2" color="" outlined x-small @click="atualizarUCs">
            <i v-i:ic#upload#16 class="btn-icon-left"></i>
            <span>Atualizar UCs</span>
          </v-btn>
        </p>
        <hr class="my-2">
        <v-row>
          <v-col cols="4">
            <v-card class="my-2 mx-2 p-1">
              <p class="m-0 p-0">
                <span class="fw-800">{{ofertas.length}}</span> ofertas carregadas (appIntegra)
              </p>
              <p class="m-0 p-0">
                <span class="fw-800">{{Object.keys(ucs).length}}</span> UCs carregadas (appIntegra)
              </p>
              <p class="m-0 p-0">
                <span class="fw-800">{{qtdeUCsComOferta}}</span> UCs com ofertas 2021/2 (appIntegra)
              </p>
            </v-card>
            <v-card class="my-2 mx-2 p-1" v-if="linhas != null">
              <p class="m-0 p-0">
                <span class="fw-800">INFORMAÇÕES ATUALIZAÇÃO</span>
              </p>
              <hr>
              <p class="m-0 p-0">
                <span class="fw-800">{{linhas.length}}</span> linhas de ofertas
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <apploading :show="loading" />
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js';
import XLSX from 'xlsx';
import moment from 'moment';
import apploading from './appLoading.vue';
import { ucsNE } from "./ucsne.js";
//import { ofertasPlan } from "./ofertas.js";

export default {
  name: "atualizarucs",
  components: { apploading },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      workbook: null,
      reader: null,
      linhas: null,
      filename: [],
      ucs: {},
      ucsNome: {},
      ucsCodigo: {},
      ofertas: {},
      qtdeUCsComOferta: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;

    rdb.ref('/curriculo/ucs').on('value',function(snapshot) {
      self.ucs = snapshot.val();
      self.ucsNome = {};
      self.ucsCodigo = {};
      self.qtdeUCsComOferta = 0;
      for(var idx in self.ucs) {
        var nome = self.ucs[idx].nome.trim().toUpperCase();
        self.ucsNome[nome] = self.ucs[idx];
        var codigo = self.ucs[idx].codigo;
        if(codigo != undefined) {
          codigo = ""+codigo;
          codigo = codigo.trim().toUpperCase();
          codigo = ""+parseInt(codigo);
        }
        self.ucsCodigo[codigo] = self.ucs[idx];
        var codigoLive = self.ucs[idx].codigoLive;
        if(codigoLive != undefined) {
          self.ucsCodigo[codigoLive] = self.ucs[idx];
        }
        if(self.ucs[idx].oferta2021S2) {
          self.qtdeUCsComOferta++;
        }
      }
      console.log("self.ucs",self.ucs);
      console.log("qtde self.ucs",Object.keys(self.ucs).length);
      console.log("self.ucsNome",self.ucsNome);
      console.log("qtde self.ucsNome",Object.keys(self.ucsNome).length);
      console.log("self.ucsCodigo",self.ucsCodigo);
      console.log("qtde self.ucsCodigo",Object.keys(self.ucsCodigo).length);
    });

    rdb.ref('/curriculo/ofertas/2021S2').on('value',function(snapshot) {
      self.ofertas = snapshotValToArray(snapshot.val());
      console.log("self.ofertas",self.ofertas);
      self.build();
    });

  },
  methods:{

    build() {
      var self = this;
      console.log("build");
      console.log(this.linhas);
      var self = this;
      var ucsOfertadas = {};
      var ucsNaoMaisOfertadas = {};

      for(var key in self.ofertas) {
        var uc = self.ofertas[key].ucID;
        if(self.ofertas[key].NOM_DISCIPLINA.trim().toLowerCase().indexOf("microcontroladores e") != -1) {
          console.log("microcontroladores",self.ofertas[key]);
        }
        ucsOfertadas[uc] = self.ofertas[key];
        if(self.ucs[uc] != undefined) {
          if(self.ucs[uc].oferta2021S2 != true) {
            ucsNaoMaisOfertadas[self.ucs[uc].nome] = self.ucs[uc];
          }
        } else {
          console.log("Não achei ",self.ofertas[key]);
        }
      }

      var ucsDescontinuadas = {};
      var saida = "";
      for(var key in self.ucs) {
        if(self.ucs[key].oferta2021S2) {
          if(ucsOfertadas[key] == undefined) {
            ucsDescontinuadas[key] = self.ucs[key];
            saida = saida + self.ucs[key].id + " " + self.ucs[key].nome + "\n";
          }
        }
      }

      console.log("self.ucsOfertadas",ucsOfertadas);
      console.log("qtde self.ucsOfertadas",Object.keys(ucsOfertadas).length);
      console.log("self.ucsNaoMaisOfertadas",ucsNaoMaisOfertadas);
      console.log("qtde self.ucsNaoMaisOfertadas",Object.keys(ucsNaoMaisOfertadas).length);
      console.log("self.ucsDescontinuadas",ucsDescontinuadas);
      console.log("qtde self.ucsDescontinuadas",Object.keys(ucsDescontinuadas).length);
      console.log("saida",saida);

    },

    atualizarUCs() {
      var self = this;
      var updates = {};
      console.log("atualizarUCs");
      console.log("self.ucs",self.ucs);
      console.log("self.ofertas",self.ofertas);
      var ucsOfertadas = {};
      var ucsSemA2 = {};
      var ucsNovas = {};


      for(var key in self.ucs) {
        updates["/curriculo/ucs/"+key+"/oferta2021S2"] = null;
      }
      console.log("updates",updates);
      console.log("qtde updates",Object.keys(updates).length);
      //rdb.ref().update(updates);
      console.log("Agora já Elvis!");
      this.toastS("Atualização realizada com sucesso!");

      updates = {};

      for(var key in self.ofertas) {
        var uc = self.ofertas[key].ucID;
        if(self.ucs[uc].avaliacaoA2 == undefined) {
          //if(self.ucs[uc].oferta2021S2 != true) {
          //  ucsNovas[uc] = self.ucs[uc].nome;
          //} else {
            ucsOfertadas[uc] = self.ucs[uc].nome;
            updates["/curriculo/ucs/"+uc+"/oferta2021S2"] = true;
          //}
        } else {
          ucsSemA2[uc] = self.ucs[uc].nome;
        }
      }
      console.log("self.ucsOfertadas",ucsOfertadas);
      console.log("qtde self.ucsOfertadas",Object.keys(ucsOfertadas).length);
      console.log("self.ucsSemA2",ucsSemA2);
      console.log("qtde self.ucsSemA2",Object.keys(ucsSemA2).length);
      console.log("self.ucsNovas",ucsNovas);
      console.log("qtde self.ucsNovas",Object.keys(ucsNovas).length);

      console.log("updates",updates);
      console.log("qtde updates",Object.keys(updates).length);
      rdb.ref().update(updates);
      console.log("Agora já Elvis!");
      this.toastS("Atualização realizada com sucesso!");
    }

  }
}
</script>

<style scoped>
</style>
