<template>
  <v-row>
    <v-col>
      <v-tabs class="pt-0 px-2 mb-4 rounded" height="23" v-model="tab" :background-color="hexTints(cor.active.b,.4)" :color="cor.active.P">
        <v-tab class="mt-0 fs-10pt" style="min-width: 0px;">
          <span class="text-nonecase f-raleway">
            <i v-i:duo#users#14 class="absolute top-0 lef-0 ms-n1"></i>
            <span class="ms-3">Equipes</span>
          </span>
        </v-tab>
        <v-tab class="mt-0 fs-10pt" style="min-width: 0px;">
          <span class="text-nonecase f-raleway">
            <i v-i:duo#users#14 class="absolute top-0 lef-0 ms-n1"></i>
            <span class="ms-3">Areas</span>
          </span>
        </v-tab>
        <v-tab class="mt-0 fs-10pt" style="min-width: 0px;">
          <span class="text-nonecase f-raleway">
            <i v-i:duo#users#14 class="absolute top-0 lef-0 ms-n1"></i>
            <span class="ms-3">Colaboradores</span>
          </span>
        </v-tab>
        <v-tab class="mt-0 fs-10pt" style="min-width: 0px;">
          <span class="text-nonecase f-raleway">
            <i v-i:duo#chart-line#14 class="absolute top-0 lef-0 ms-n1"></i>
            <span class="ms-3">Acessos</span>
          </span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" v-bg:b class="">
        <v-tab-item>
          <equipes />
        </v-tab-item>
        <v-tab-item>
          <setores />
        </v-tab-item>
        <v-tab-item class="">
          <crudcolaboradores />
        </v-tab-item>
        <v-tab-item class="">
          <acessosview />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import crudcolaboradores from "../cruds/colaboradores/crud.vue"
import equipes from "./equipes.vue"
import setores from "./setores.vue"
import acessosview from "./acessos-view.vue";

export default {
  name: "appintegratabshome",
  components: { crudcolaboradores, equipes, setores, acessosview},
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      tab: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
