<template>
  <div class="row">
    <div class="col-12 col-lg-12">
      <v-card class="mb-0 pb-0 mt-0 pt-0">
        <v-card-text class="mb-0 pb-0">
          <v-row class="mb-1">
            <v-col class="py-0 mb-2" cols="12" md="4" lg="3">
              <p class="fs-12pt pt-2 pb-0 mb-0 fw-400 f-yanone text-left">
                <v-icon class="fs-15pt">mdi-account-multiple</v-icon>
                Lista de áreas
              </p>
            </v-col>
          </v-row>
          <!-- <p><VueObjectView v-model="value" :key="refreshKey"/></p> -->

          <div v-if="loading" class="mt-4">
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-skeleton-loader class="mb-6" elevation="2" type="list-item-avatar-three-line"></v-skeleton-loader>
          </div>

          <v-alert v-if="!loading && setores.length == 0" class="mt-4" outlined color="grey" type="warning" prominent border="left">
            <p class="" style="color: black;">
              Nenhum resultado encontrado.
            </p>
          </v-alert>

          <hr class="mt-4 mb-2">

          <v-list two-line>
            <div class="" v-for="setor in setores">
              <v-list-item class="mb-0 pb-0 mt-n2">
                <v-list-item-avatar size="52" class="mt-0">
                  <v-avatar size="52">
                    <i v-i:duo#users#30 :style="'color: '+setor.cor.hexa" v-if="setor.cor != undefined"></i>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content class="text-left">
                  <v-list-item-title class="my-0 py-0" v-if="setor.cor != undefined">
                    <p class="ms-2 my-0 py-0 fs-11pt f-raleway" :style="'color: '+setor.cor.hexa">
                      <span class="fw-500">{{setor.nome}}</span>
                    </p>
                  </v-list-item-title>
                  <v-list-item-subtitle class="ms-0 ps-0 my-0 py-0">
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon  class="px-0 mx-0">
                  <v-btn class="px-0 mx-0" :color="cor.active.D" small icon @click="$refs.promptdialogref.show(setor)">
                    <i class="btn-icon-left ps-1" v-i:duo#trash#20></i>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-icon  class="px-0 mx-0 ms-1">
                  <v-btn class="px-0 mx-0" :color="cor.active.I" small icon @click="formShow(setor)">
                    <i class="btn-icon-left ps-1" v-i:duo#pencil#20></i>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
              <v-divider inset class="mt-n4"></v-divider>
            </div>
          </v-list>

        </v-card-text>
      </v-card>
    </div>

    <formsetor ref="formsetorref" @update="formUpdate"/>
    <promptdialog ref="promptdialogref" @confirm="confirmaRemover" />

  </div>

</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import formsetor from "./formSetor.vue";
import promptdialog from "./promptdialog.vue";

export default {
  name: "setores",
  components: { formsetor, promptdialog },
  data(){
    return {
      loading: false,
      setores: [],
      setorParaAtualizar: {},
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    rdb.ref('/usuarios/setores').on('value', function(snapshot) {
      self.setores = snapshot.val();
      self.loading = false;
      console.log(self.setores);
    });
  },

  methods:{

    formShow(item) {
      const setor = clone2(item);
      this.setorParaAtualizar = item;
      this.$refs.formsetorref.show(setor);
    },

    formUpdate(dados) {
      console.log("dados",dados);
      var updates = {};
      var elem = clone2(dados.formData);
      if(dados.tipo == "Atualizar") {
        updates["/usuarios/setores/"+elem.id] = elem;
        console.log("this.setorParaAtualizar",this.setorParaAtualizar);
      } else {
        var newKey = rdb.ref('/usuarios/setores/').push().key;
        elem.id = newKey;
        updates["/usuarios/setores/"+newKey] = elem;
      }
      console.log(updates);
      rdb.ref().update(updates);
      this.toastS("Dados cadastrados com sucesso!");
      console.log("agora já elvis!");
    },

    confirmaRemover(item) {
      console.log("confirmaRemover",item);
      var elem = clone2(item);
      console.log("confirmaRemover",elem);
      var updates = {};
      for(var key in elem.pessoas) {
        updates["/usuarios/pessoas/"+key+"/equipes/"+elem.id] = null;
      }
      updates["/usuarios/equipes/"+elem.id] = null;
      console.log(updates);
      //rdb.ref().update(updates);
      this.toastS("Item removido com sucesso!");
      console.log("agora já elvis!");
    }

  }
}
</script>

<style scoped>

</style>
