<template>
  <v-row>
    <v-col>
      <v-tabs class="pt-0 px-2 mb-4 rounded" height="23" v-model="tab" :background-color="hexTints(cor.active.b,.4)" :color="cor.active.P">
        <v-tab class="mt-0 fs-10pt" style="min-width: 0px;">
          <span class="text-nonecase f-raleway">
            <i v-i:duo#graduation-cap#14 class="absolute top-0 lef-0 ms-n2"></i>
            <span class="ms-3">UCs</span>
          </span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" v-bg:b class="mt-n1 mx-2">
        <v-tab-item>
          <ucs />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import ucs from "./ucs.vue"

export default {
  name: "e2atabshome",
  components: { ucs },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      tab: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
