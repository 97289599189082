<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card class="pb-2">
      <v-card-title class="fs-13pt my-0 pb-1" v-c:I>
        <span class="fw-700 f-raleway">{{btnName}}</span>
        <span class="ps-1 fw-300 f-raleway">nova área</span>
      </v-card-title>
      <v-divider class="mt-0 mb-1 py-0"></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12 my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Nome da área</p>
              <v-text-field v-model="formData.nome" :rules="[rules.required]" solo dense label="Nome da área" required placeholder="Digite o nome da equipe"></v-text-field>
            </v-col>
            <v-col cols="12 my-0 py-0">
              <p class="fw-300 f-roboto">
                Selecione a cor da área:
              </p>
              <p v-if="formData.cor != null">
                <i class="me-2 btn-icon-left fw-400" v-i:duo#calendar#26 :style="'color: '+formData.cor.hexa"></i>
                <span class="f-raleway fs-12pt" :style="'color: '+formData.cor.hexa">{{formData.nome}}</span>
                <span class="ms-4">cor: {{formData.cor.hexa}}</span>
              </p>
              <v-color-picker v-model="formData.cor" class="ma-2" show-swatches></v-color-picker>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn :color="cor.active.S" small @click="dialog = false">
          cancelar
        </v-btn>
        <v-btn :color="cor.active.V" v-c:B small @click="update">
          <i class="btn-icon-left" v-i:duo#check-circle#18></i>
          {{btnName}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";

export default {
  name: "formsetor",
  components: { },
  data() {
    return {
      dialog: false,
      loading: false,
      btnName: "Adicionar",
      formData: {
        nome: "",
        cor: null,
      },
      rules: {
        required: value => !!value || 'Obrigatório',
      },
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    self.loading = false;
  },

  methods:{

    show(dados) {
      console.log("dados",dados);
      this.dialog = true;
      if(dados == null) {
        this.formData = { nome: "", cor: null };
        this.btnName = "Adicionar";
      } else {
        this.formData = clone2(dados);
        this.btnName = "Atualizar";
      }
      console.log("this.formData",this.formData);
    },

    update() {
      if(this.formData.nome == "") {
        this.toastD("Nome da área obrigatória!");
        return;
      }
      this.dialog = false;
      this.$emit("update",{ formData: this.formData, tipo: this.btnName } );
    }

  }
}
</script>

<style scoped>

</style>
