
export const cargos = [
  { id: 'Educador(a)', nome: 'Educador(a)', label: 'Educador(a)' },
  { id: 'Professor(a)', nome: 'Professor(a)', label: 'Professor(a)' },
  { id: "Gestor(a) de Área", nome: "Gestor(a) de Área", label: "Gestor(a) de Área" },
  { id: 'Assessor(a) de Área', nome: 'Assessor(a) de Área', label: 'Assessor(a) de Área' },
  { id: 'Coordenador(a) Regional', nome: 'Coordenador(a) Regional', label: 'Coordenador(a) Regional' },
  { id: 'Coordenador(a) de Área', nome: 'Coordenador(a) de Área', label: 'Coordenador(a) de Área' },
  { id: 'Coordenador(a) de Cursos', nome: 'Coordenador(a) de Cursos', label: 'Coordenador(a) de Cursos' }
];
