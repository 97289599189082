<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined class="mt-3">
        <p class="my-2 mx-2">
          Importar Ofertas 2021.2
          <v-btn v-if="false" class="ms-2" color="" outlined x-small @click="corrigirUCs">
            <i v-i:ic#upload#16 class="btn-icon-left"></i>
            <span>corrigirUCs</span>
          </v-btn>
          <v-btn class="ms-2" color="" outlined x-small @click="lab">
            <i v-i:ic#upload#16 class="btn-icon-left"></i>
            <span>lab</span>
          </v-btn>
          <v-btn class="ms-2" color="" outlined x-small @click="$refs.fileinputref.$refs.input.click()">
            <i v-i:ic#upload#16 class="btn-icon-left"></i>
            <span>carregar planilha SGD</span>
          </v-btn>
          <span v-if="filename.length != 0">
            <span class="ms-3 f-sanspro fw-600">Arquivo: </span>
            <span class="ms-1 f-raleway fw-400">{{filename.name}}</span>
          </span>
        </p>
        <hr class="my-2">
        <v-file-input v-show="false" ref="fileinputref" v-model="filename" label="Planilha" @change="parseExcelFile1"></v-file-input>
        <v-row>
          <v-col cols="4">
            <v-card class="my-2 mx-2 p-1">
              <p class="m-0 p-0">
                <span class="fw-800">{{ofertas.length}}</span> ofertas carregadas (appIntegra)
              </p>
            </v-card>
            <v-card class="my-2 mx-2 p-1" v-if="linhas != null">
              <p class="m-0 p-0">
                <span class="fw-800">INFORMAÇÕES CARREGADAS DA PLANILHA</span>
              </p>
              <hr>
              <p class="m-0 p-0">
                <span class="fw-800">{{linhas.length}}</span> linhas de ofertas
              </p>
              <p class="m-0 p-0">
                <span class="fw-800" v-c:V>{{qtdeOfertasE2A2}}</span>
                <span class="ms-1 f-sanspro fw-600" v-c:V#3>ofertas para o E2A2</span>
              </p>
              <p class="m-0 p-0">
                <span class="fw-800" v-c:D>- {{qtdeOfertasAContratar}}</span>
                <span class="ms-1 f-sanspro fw-600" v-c:D#3>ofertas descartadas (a contratar)</span>
              </p>
              <p class="m-0 p-0">
                <span class="fw-800" v-c:D>- {{qtdeEstagioEncontradas}}</span>
                <span class="ms-1 f-sanspro fw-600" v-c:D#3>ofertas descartadas (estágio/práticas)</span>
              </p>
              <hr class="m-1">
              <p class="m-0 p-0">
                <span class="fs-13pt fw-800" v-cHex="'#5e1100'">{{qtdeOfertasIndicadas}}</span>
                <span class="ms-1 fs-12pt f-sanspro fw-400" v-cHex="'#5e1100'">OFERTAS PARA CARREGAR</span>
              </p>
            </v-card>
            <v-card class="my-2 mx-2 p-1" v-if="linhas != null">
              <p class="m-0 p-0" v-c:V v-if="qtdeUCsNaoEncontradas == 0">
                <span class="fw-800">{{qtdeUCsNaoEncontradas}}</span> ofertas com UCs NÃO encontradas
              </p>
              <p class="m-0 p-0" v-c:D v-if="qtdeUCsNaoEncontradas > 0">
                <span class="fw-800">{{qtdeUCsNaoEncontradas}}</span> ofertas com UCs NÃO encontradas
              </p>
              <p class="m-0 p-0" v-c:V>
                <span class="fw-800">{{qtdeProfsCPF+qtdeProfsNome}}</span> ofertas com professores encontrados
              </p>
              <p class="m-0 p-0">
                <span class="fw-800" v-c:D>{{qtdeProfsNaoEncontrados}}</span>
                <span class="ms-1 f-sanspro fw-600" v-c:D#3>ofertas com professores NÃO encontrados</span>
              </p>
              <hr class="m-1">
              <p class="m-0 p-0">
                <span class="fs-13pt fw-800" v-cHex="'#5e1100'">{{Object.keys(profsNaoEncontrados).length}}</span>
                <span class="ms-1 fs-12pt f-sanspro fw-400" v-cHex="'#5e1100'">professores NOVOS a cadastrar</span>
              </p>
            </v-card>
            <v-card class="my-2 mx-2 p-1" v-if="true || linhas != null">
              <v-btn class="my-1 text-nonecase fs-10pt fw-600 f-sanspro" v-c:D block outlined small @click="importar">
                <i v-i:ic#upload#16 class="btn-icon-left"></i>
                <span>Cadastrar profs e Importar ofertas</span>
              </v-btn>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card class="my-2 mx-2 p-1" v-if="profsNaoEncontrados != null">
              <p class="m-0 p-0 fw-800">
                profs não encontrados
                <span class="ms-2 f-sanspro fs-12pt">{{Object.keys(profsNaoEncontrados).length}}</span>
              </p>
              <v-card class="p-2" v-for="(item,index) in profsNaoEncontrados" :key="index">
                <p class="m-0 p-0">
                  <span class="f-sanspro fw-600 fs-10pt">CPF: </span>
                  <span class="f-raleway fw-400 fs-10pt">{{item[0].CPF_PROFESSOR}}</span>
                  <span class="ms-1 f-sanspro fw-600 fs-10pt">IEs: </span>
                  <span class="f-raleway fw-400 fs-10pt">{{item[0].SGL_IES_ORIGEM}}</span>
                </p>
                <p class="m-0 p-0">
                  <span class="f-sanspro fw-600 fs-10pt">Nome: </span>
                  <span class="f-raleway fw-400 fs-10pt">{{item[0].NOM_PROFESSOR}}</span>
                  <v-btn class="ms-1 px-1 py-0 f-sanspro text-nonecase" color="primary" x-small @click="sugestoes(item[0].NOM_PROFESSOR)">
                    sugestões
                  </v-btn>
                </p>
                <p class="m-0 p-0">
                  <span class="f-sanspro fw-600 fs-10pt">Ofertas: </span>
                  <span class="f-raleway fw-400 fs-10pt">{{item.length}} ofertas</span>
                  <editobject :obj="item[0]" @onSave="onSave" />
                </p>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <apploading :show="loading" />
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js';
import XLSX from 'xlsx';
import moment from 'moment';
import apploading from './appLoading.vue';
import editobject from './edit-object.vue';
//import { ucsNE } from "./ucsne.js";
import { ofertasPlan } from "./ofertas.js";

export default {
  name: "ofertassgd",
  components: { apploading, editobject },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      workbook: null,
      reader: null,
      linhas: null,
      filename: [],
      pessoas: {},
      pessoasCPF: {},
      pessoasNome: {},
      ucs: {},
      ucsNome: {},
      ucsCodigo: {},
      iesdb: {},
      iesdbNomes: {},
      ofertas: [],
      ofertasImportar: [],
      qtdeOfertasE2A2: 0,
      qtdeProfsCPF: 0,
      qtdeProfsNome: 0,
      qtdeProfsNaoEncontrados: 0,
      qtdeOfertasAContratar: 0,
      qtdeOfertasIndicadas: 0,
      qtdeEstagioEncontradas: 0,
      profsNaoEncontrados: [],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    rdb.ref('/usuarios/pessoas').on('value',function(snapshot) {
      self.pessoas = snapshot.val();
      self.pessoasCPF = {};
      self.pessoasNome = {};
      for(var idx in self.pessoas) {
        var cpf = self.pessoas[idx].cpf;
        if(cpf == undefined) {
          cpf = "0";
        } else {
          cpf = ""+parseInt(cpf.trim());
        }
        self.pessoasCPF[cpf] = self.pessoas[idx];
        var nome = self.pessoas[idx].nome.trim().toUpperCase();
        nome = nome.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        self.pessoasNome[nome] = self.pessoas[idx];
      }
      console.log("self.pessoas",self.pessoas);
      console.log("qtde self.pessoas",Object.keys(self.pessoas).length);
      console.log("self.pessoasCPF",self.pessoasCPF);
      console.log("qtde self.pessoasCPF",Object.keys(self.pessoas).length);
      console.log("self.pessoasNome",self.pessoasNome);
      console.log("qtde self.pessoasNome",Object.keys(self.pessoasNome).length);
    });

    rdb.ref('/curriculo/ucs').on('value',function(snapshot) {
      self.ucs = snapshot.val();
      self.ucsNome = {};
      self.ucsCodigo = {};
      for(var idx in self.ucs) {
        var nome = self.ucs[idx].nome.trim().toUpperCase();
        var codigo = self.ucs[idx].codigo;
        if(codigo != undefined) {
          codigo = ""+codigo;
          codigo = codigo.trim().toUpperCase();
          codigo = ""+parseInt(codigo);
        }
        self.ucsNome[nome] = self.ucs[idx];
        self.ucsCodigo[codigo] = self.ucs[idx];
        var codigoLive = self.ucs[idx].codigoLive;
        if(codigoLive != undefined) {
          self.ucsCodigo[codigoLive] = self.ucs[idx];
        }
      }
      console.log("self.ucs",self.ucs);
      console.log("qtde self.ucs",Object.keys(self.ucs).length);
      console.log("self.ucsNome",self.ucsNome);
      console.log("qtde self.ucsNome",Object.keys(self.ucsNome).length);
      console.log("self.ucsCodigo",self.ucsCodigo);
      console.log("qtde self.ucsCodigo",Object.keys(self.ucsCodigo).length);
    });

    //console.log("ucsNE",ucsNE);
    //console.log("ofertasPlan",ofertasPlan);

    rdb.ref('/curriculo/ofertas/2021S2').on('value',function(snapshot) {
      self.ofertas = snapshotValToArray(snapshot.val());
      console.log("self.ofertas",self.ofertas);
    });

    rdb.ref('/curriculo/ies').on('value', function(snapshot) {
      self.iesdb = snapshot.val();
      self.iesdbNomes = {};
      for(var key in self.iesdb) {
        self.iesdbNomes[self.iesdb[key].ies] = self.iesdb[key];
      }
      console.log("self.iesdb",self.iesdb);
      console.log("self.iesdbNomes",self.iesdbNomes);
    });

    var profs = localStorage.getItem('profsNaoEncontrados');
    self.profsNaoEncontrados = JSON.parse(profs);
    console.log("self.profsNaoEncontrados",self.profsNaoEncontrados);

  },
  methods:{

    build() {
      var self = this;
    },

    parseXLSX(abaIdx) {
      var arrayBuffer = this.reader.result;
      var options = { type: 'array' };
      var workbook = XLSX.read(arrayBuffer, options);
      console.timeEnd();
      var sheetName = workbook.SheetNames
      var sheet = workbook.Sheets[sheetName[abaIdx]]
      this.linhas = XLSX.utils.sheet_to_json(sheet, {raw: false});
      console.log(this.linhas);
      this.loading = false;
      this.refreshKey += 1;
      this.$forceUpdate();
      this.build();
    },

    parseExcelFile1(file) {
      var self = this;
      self.loading = true;
      console.log("parseExcelFile1",file);
      console.log(this.$refs.fileinputref);
      if(file == undefined) return;
      console.time();
      this.reader = new FileReader();
      this.reader.onloadend = function(event) {
        self.parseXLSX(0);
      };
      this.reader.readAsArrayBuffer(file);
    },

    parseAba(abaIdx) {
      this.parseXLSX(abaIdx)
      this.$forceUpdate();
    },

    build() {
      console.log("build");
      console.log(this.linhas);
      var self = this;
      var saida = "";

      self.qtdeOfertasE2A2 = 0;
      self.qtdeProfsCPF = 0;
      self.qtdeProfsNome = 0;
      self.qtdeProfsNaoEncontrados = 0;
      self.qtdeOfertasAContratar = 0;
      self.qtdeOfertasIndicadas = 0;
      self.profsNaoEncontrados = {};
      self.qtdeUCsEncontradas = 0;
      self.qtdeUCsNaoEncontradas = 0;
      self.qtdeEstagioEncontradas = 0;
      self.ucssNaoEncontradas = {};
      self.ofertasImportar = [];

      for(var idx in this.linhas) {
        var versaoGrade = "";
        if(this.linhas[idx].VERSAO_GRADE != undefined) {
          versaoGrade = this.linhas[idx].VERSAO_GRADE;
        }
        var idtTurma = "";
        if(this.linhas[idx].IDT_TURMA != undefined) {
          idtTurma = this.linhas[idx].IDT_TURMA;
        }

        if(versaoGrade.lastIndexOf("E2A2") != -1 || idtTurma.lastIndexOf("COR") != -1) {
          self.qtdeOfertasE2A2++;

          var ucNome = this.linhas[idx].NOM_DISCIPLINA;
          if(ucNome.trim().toLowerCase().indexOf("biodiversidade") != -1) {
            console.log("achei: "+ucNome,this.linhas[idx]);
          }

          if(ucNome != undefined) {
            ucNome = ucNome.trim().toUpperCase();
            var vet = ucNome.split("(");
            if(vet.length>1) {
              ucNome = vet[0].trim();
            }
            ucNome = ucNome.replaceAll("P1-","");
            ucNome = ucNome.replaceAll("P2-","");
            ucNome = ucNome.replaceAll("P3-","");
            ucNome = ucNome.replaceAll("P4-","");
            ucNome = ucNome.replaceAll("P5-","");
            ucNome = ucNome.replaceAll("P6-","");
            ucNome = ucNome.replaceAll("P7-","");
            ucNome = ucNome.replaceAll("P8-","");
            ucNome = ucNome.replaceAll("P9-","");

            var isEstagio = function(nome) {
              if(ucNome.toUpperCase().lastIndexOf("ESTÁGIO") == -1) {
                if(ucNome.toUpperCase().lastIndexOf("PRÁTICAS VETERINÁRIAS:") == -1) {
                  if(ucNome.toUpperCase().lastIndexOf("TRABALHO DE CONCLUSÃO DE CURSO") == -1) {
                    if(ucNome.toUpperCase().lastIndexOf("PERSONALIZÁVEL") == -1) {
                      if(ucNome.toUpperCase().lastIndexOf("TCC") == -1) {
                        return false;
                      }
                    }
                  }
                }
              }
              return true;
            };

            if(isEstagio(ucNome)) {
              self.qtdeEstagioEncontradas++;
            } else {
              var codigo = ""+parseInt(this.linhas[idx].SGL_DISCIPLINA.trim());
              if(self.ucsCodigo[codigo] != undefined) {
                self.qtdeUCsEncontradas++;
                this.linhas[idx].ucID = self.ucsCodigo[codigo].id;
              } else {
                if(self.ucsNome[ucNome] != undefined) {
                  self.qtdeUCsEncontradas++;
                  this.linhas[idx].ucID = self.ucsNome[ucNome].id;
                } else {
                  self.qtdeUCsNaoEncontradas++;
                  if(self.ucssNaoEncontradas[ucNome] == undefined) {
                    self.ucssNaoEncontradas[ucNome] = [];
                  }
                  self.ucssNaoEncontradas[ucNome].push(this.linhas[idx]);
                }
              }

              var nomeProf = this.linhas[idx].NOM_PROFESSOR;
              if(nomeProf != undefined) {
                nomeProf = nomeProf.trim().toUpperCase();
                if(nomeProf == "A CONTRATAR" || nomeProf == "ESTUDOS AUTÔNOMOS - SEM PROFESSOR" || nomeProf == "PRECEPTOR - PRÁTICA SUPERVISIONADA") {
                  self.qtdeOfertasAContratar++;
                } else {
                  if(this.linhas[idx].CPF_PROFESSOR != undefined) {
                    var cpf = ""+parseInt(this.linhas[idx].CPF_PROFESSOR.trim());
                    if(self.pessoasCPF[cpf] != undefined) {
                      self.qtdeProfsCPF++;
                      this.linhas[idx].profID = self.pessoasCPF[cpf].id;
                    } else {
                      nomeProf = nomeProf.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                      if(self.pessoasNome[nomeProf] != undefined) {
                        self.qtdeProfsNome++;
                        this.linhas[idx].profID = self.pessoasNome[nomeProf].id;
                      } else {
                        if(self.profsNaoEncontrados[cpf] == undefined) {
                          self.profsNaoEncontrados[cpf] = [];
                        }
                        self.profsNaoEncontrados[cpf].push(this.linhas[idx]);
                        self.qtdeProfsNaoEncontrados++;
                      }
                    }
                  } else {

                  }
                  self.qtdeOfertasIndicadas++;
                  //var ies = this.iesdbNomes[this.linhas[idx].SGL_IES_ORIGEM.trim().toUpperCase()];
                  //this.linhas[idx].ies = ies;
                  self.ofertasImportar.push(this.linhas[idx]);
                }

              }
            }
          }

        } else {
          saida = saida + this.linhas[idx].NOM_DISCIPLINA + "\n";
        }

      }

      console.log("self.profsNaoEncontrados",self.profsNaoEncontrados);

      localStorage.setItem('profsNaoEncontrados', JSON.stringify(self.profsNaoEncontrados));

      console.log("qtde self.profsNaoEncontrados",Object.keys(self.profsNaoEncontrados).length);

      console.log("self.ucssNaoEncontradas",self.ucssNaoEncontradas);
      console.log("qtde self.ucssNaoEncontradas",Object.keys(self.ucssNaoEncontradas).length);

      console.log("self.ofertasImportar",self.ofertasImportar);

      //console.log("saida",saida);

    },

    importar() {
      var self = this;
      console.log("importar",self.ofertasImportar);
      console.log("self.profsNaoEncontrados",self.profsNaoEncontrados);
      console.log("qtde self.profsNaoEncontrados",Object.keys(self.profsNaoEncontrados).length);
      var profsCPF = {};
      var ofertas = [];
      var updates = {};
      var ofertasNovosProfs = [];
      self.loading = true;

      //EXPORTAR OFERTAS BACKUP
      var vet = [];
      for(var idx in this.ofertas) {
        vet.push(clone2(this.ofertas[idx]));
      }
      const fileName = "appIntegra-BACKUP-OFERTAS-"+moment().format()+".xlsx";
      const ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
      const wb = XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "appIntegra-OFERTAS");
      XLSX.writeFile(wb, fileName);

      console.log("vet",vet);
      //EXCLUIR OFERTAS
      var qtdeLote = 0;
      for(var idx in vet) {
        qtdeLote++;
        updates["/curriculo/ofertas/2021S2/"+vet[idx].id] = null;
        if(qtdeLote % 1000 == 0) {
          console.log("Lote enviado: "+qtdeLote);
          console.log("updates",updates);
          rdb.ref().update(updates);
          updates = {};
        }
      }
      console.log("Lote enviado: "+qtdeLote);
      console.log("updates",updates);
      rdb.ref().update(updates);
      updates = {};

      for(var key in self.profsNaoEncontrados) {
        var linha = self.profsNaoEncontrados[key][0];
        var newKey1 = rdb.ref('/usuarios/pessoas/').push().key;
        var prof = {
          id: newKey1,
          cpf: linha.CPF_PROFESSOR.trim(),
          nome: linha.NOM_PROFESSOR.trim().toUpperCase(),
          ies: linha.SGL_MARCA_ORIGEM,
          senha: linha.NOM_PROFESSOR.trim().toLowerCase().substr(0,4),
          formacao: { area: "", titulacao: "" }, grandeArea: 0,
          regional: "", unidade: "", email: "", telefone: "", admin: false, equipes: {}, setores: {},
          cargo: "Professor(a)", urlCapa: "https://placeimg.com/1200/200/nature", urlFoto: "https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/responsaveis%2Fdummy.png?alt=media&token=f785c7d3-f03c-4311-acec-983e2f9e9103",
        }
        profsCPF[prof.cpf] = prof;
        updates['/usuarios/pessoas/'+prof.id] = prof;
      }
      console.log("updates",updates);
      console.log("qtde updates",Object.keys(updates).length);
      rdb.ref().update(updates);

      updates = {};
      var lote = 0;
      for(var idx in self.ofertasImportar) {
        var newKey = rdb.ref('/curriculo/ofertas/2021S2/').push().key;
        var oferta = clone(self.ofertasImportar[idx]);
        oferta.id = newKey;
        delete oferta.CENTRO_CUSTO;
        delete oferta.CH_TOTAL_DISCIPLINA;
        delete oferta.COD_CAMPUS;
        delete oferta.COD_CURSO;
        delete oferta.COD_DISCIPLINA;
        delete oferta.COD_FUNCIONARIO;
        delete oferta.COD_GRD_HORARIO;
        delete oferta.COD_INDICACAO_DET_PROF;
        delete oferta.COD_PESSOA;
        delete oferta.COD_PROFESSOR;
        delete oferta.DAT_CADASTRO;
        delete oferta.DAT_FIM_INDICACAO;
        delete oferta.DAT_INI_INDICACAO;
        delete oferta.DAT_REF_EXPORTACAO;
        delete oferta.DSC_OCORRENCIA;
        delete oferta.IND_PAGAR_CH;
        delete oferta.IND_TEMPORARIO;
        delete oferta.NOM_CAMPUS;
        delete oferta.NOM_DEPARTAMENTO;
        delete oferta.NOM_STA_INDICACAO;
        delete oferta.NUM_CH_DISCIPLINA;
        delete oferta.NUM_CH_INDICADA;
        delete oferta.NUM_CH_PAGA;
        delete oferta.NUM_MATRICULA;
        delete oferta.SGL_DISCIPLINA;
        delete oferta.SGL_IES_DESTINO;
        delete oferta.SGL_IES_ORIGEM;
        delete oferta.SGL_MARCA_DESTINO;
        delete oferta.SGL_MARCA_ORIGEM;
        delete oferta.TEM_MULTI_MATRICULA;

        if(oferta.profID == undefined) {
          var profID = profsCPF[oferta.CPF_PROFESSOR.trim()].id;
          oferta.profID = profID;
          ofertasNovosProfs.push(oferta);
        }
        ofertas.push(oferta);
        updates['/curriculo/ofertas/2021S2/'+newKey] = oferta;
        lote++;
        if(lote % 3000 == 0) {
          console.log("Lote enviado: "+lote);
          console.log("updates",updates);
          rdb.ref().update(updates);
          updates = {};
        }
      }
      console.log("Lote enviado: "+lote);
      console.log("updates",updates);
      rdb.ref().update(updates);
      updates = {};

      console.log("ofertasNovosProfs",ofertasNovosProfs);
      console.log("ofertas",ofertas);
      console.log("Agora já elvis!!!!");
      self.loading = false;

    },

    corrigirUCs() {
      console.log("corrigirUCs");
      var ucsSemCodigo = [];
      var updates = {};

      for(var key in this.ucs) {
        var uc = this.ucs[key];
        if(uc.codigo == "sem_codigo") {
          ucsSemCodigo.push(uc);
        } else {
          var codigo = ""+parseInt(uc.codigo.trim());
          updates["/curriculo/ucs/"+uc.id+"/codigo"] = codigo;
        }
      }

      console.log("ucsSemCodigo",ucsSemCodigo);
      console.log("updates",updates);
      //rdb.ref().update(updates);
      console.log("Agora já elvis!!!!");

    },

    onSave(obj) {
      var self = this;
      obj = clone2(obj);
      console.log("onSave",obj);
      var newKey = rdb.ref('/usuarios/pessoas/').push().key;
      var user = {
        id: newKey,
        nome: obj.NOM_PROFESSOR,
        cpf: parseInt(obj.CPF_PROFESSOR),
        senha: obj.NOM_PROFESSOR.trim().toLowerCase().substr(0,4),
        grandeArea: 0,
        ies: obj.SGL_MARCA_ORIGEM,
        unidade: obj.NOM_CAMPUS,
        urlCapa: "https://placeimg.com/1200/200/nature",
        urlFoto: "https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/responsaveis%2Fdummy.png?alt=media&token=f785c7d3-f03c-4311-acec-983e2f9e9103",
        regional: "",
        email: obj.email,
        telefone: "",
        admin: false,
        equipes: {},
        setores: {},
        cargo: "Professor(a)",
        formacao: {
          area: "",
          titulacao: obj.NOM_NIVEL_FORMACAO,
        }
      }
      console.log("user",user);

      var updates = {};
      updates["/usuarios/pessoas/"+user.id] = user;
      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("Agora já elvis!!!!");

    },

    sugestoes(nome) {
      console.log("nome",nome);
      var self = this;
      var sugestoes = {};
      nome = nome.trim().toUpperCase();
      nome = nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      for(var key in self.pessoasNome) {
        var nome2 = key.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        var value = stringSimilarity.compareTwoStrings(nome,nome2);
        if(value > 0.5) {
          sugestoes[key] = self.pessoasNome[key];
        }
      }
      console.log("sugestoes",sugestoes);
    },

    lab() {
      console.log("lab");
      console.log("ofertasPlan",ofertasPlan);

      for(var key in ofertasPlan) {
        if(ofertasPlan[key].NOM_DISCIPLINA.trim().toLowerCase().indexOf("microcontroladores e") != -1) {
          console.log("microcontroladores",ofertasPlan[key]);
        }
      }

    }

  }
}
</script>

<style scoped>
</style>
