<template>
  <v-row class="">
    <v-col cols="12" md="6" lg="4" class="m-0">
      <cardacessos />
    </v-col>
    <v-col cols="12" md="6" lg="4" class="m-0">
      <cardpessoasonline />
    </v-col>
    <v-col cols="12" md="6" lg="4" class="m-0">
      <cardpessoaslast />
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import cardacessos from "../cards/card-acessos.vue";
import cardpessoasonline from "../cards/card-pessoas-online.vue";
import cardpessoaslast from "../cards/card-pessoas-last.vue";

export default {
  name: "",
  components: { cardacessos, cardpessoasonline, cardpessoaslast },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
