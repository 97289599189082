<template>
  <v-card class="py-2 px-3">
    <div class="">
      <p class="ms-0 my-0 f-sanspro fw-600 fs-10pt" v-cHex="hexShades(cor.active.B,0.5)">
        <i class="btn-icon-left" v-i:duo#users#18></i>
        Acessos
      </p>
    </div>
    <v-card class="py-1 px-2 mt-3" v-bg:b#4 @click="$emit('cardClick')">
      <p class="ms-0 my-0 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
        <i class="btn-icon-left" v-i:duo#users#16></i>
        <span class="">Pessoas on-line</span>
        <span class="absolute ms-1" v-i:ic#circle#10 v-c:V></span>
      </p>
      <p class="ms-0 mt-2 mb-1 f-raleway fw-500 fs-10pt" v-cHex="hexShades(cor.active.B,0.5)">
        <span class="fw-800">{{pessoasOnline.length}}</span>
        <span class="ms-1 fw-400">pessoas online neste momento</span>
      </p>
    </v-card>
    <div class="mt-3">
      <p class="ms-0 my-0 f-sanspro fw-400 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
        <span class="fw-600 fs-11pt">Acessos</span> (total de sete dias)
      </p>
      <p class="ms-0 my-0 mt-n1 f-roboto fw-500 fs-13pt" v-cHex="hexShades(cor.active.B,1)">
        <span class="border px-1 rounded">{{pessoasTotal}}</span>
      </p>
      <v-row class="p-0 mx-1 mt-1 mb-0">
        <v-col cols="2" class="m-0 p-0" v-for="idx in [0,1,2,3,4,5]" :key="idx">
          <span class="fs-8pt f-roboto fw-800">{{labelDias[idx]}}</span>
          <span class="ms-1 fs-7pt f-raleway fw-300" v-if="labelDiasSem[idx] != undefined">{{labelDiasSem[idx].toUpperCase()}}</span>
        </v-col>
      </v-row>
      <v-sheet color="transparent" class="mt-n3">
        <v-sparkline :value="ultimosDias" label-size="12" :color="cor.active.I" height="80" padding="14" auto-draw smooth>
          <template v-slot:label="item">
            {{item.value}}
          </template>
        </v-sparkline>
      </v-sheet>
      <div class="ms-0 mt-3 mb-1 text-right f-raleway fw-500 fs-12pt" v-cHex="hexShades(cor.active.B,0.5)">
        <v-btn @click="$emit('cardClick')" class="text-nonecase f-sanspro fw-400 fs-8pt" v-c:I outlined x-small>
          ver detalhes
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import moment from "moment";

export default {
  name: "cardacessos",
  components: {},
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      pessoasOnline: [],
      pessoasTotal: 0,
      ultimosDias: [ 0, 0, 0, 0, 0, 0, 0 ],
      labelDias: [],
      labelDiasSem: [],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    var agora = moment().subtract(1,"minutes").format("YYYY-MM-DDTHH:mm");
    rdb.ref('/usuarios/pessoasLogControl').orderByChild("last").startAfter(agora).on('value',function(snapshot) {
      var values = snapshot.val();
      //console.log("values",values);
      self.pessoasOnline = [];
      for(var key in values) {
        //console.log("key",key);
        if(values[key].logged) {
          rdb.ref('/usuarios/pessoas/'+key).once('value',function(snapshot) {
            var user = snapshot.val();
            if(user != null) {
              self.pessoasOnline.push(user);
            }
          });
        }
      }
      //console.log("self.pessoasOnline",self.pessoasOnline);
    });

    var setedias = moment().subtract(7,"days").format("YYYY-MM-DDTHH:mm");
    rdb.ref('/usuarios/pessoasLogControl').orderByChild("last").startAfter(setedias).on('value',function(snapshot) {
      var values = snapshot.val();
      //console.log("values",values);
      var vet = [];
      self.pessoasTotal = 0;
      self.ultimosDias = [ 0, 0, 0, 0, 0, 0 ];
      for(var i=0; i<6;i++) {
        self.labelDias[i] = moment().subtract(5-i,"days").format("DD");
        self.labelDiasSem[i] = moment().subtract(5-i,"days").format("ddd");
      }
      for(var key in values) {
        for(var idx in values[key].backlogs) {
          self.pessoasTotal++;
          var time = values[key].backlogs[idx];
          var dias = 5-moment().diff(time,"days");
          self.ultimosDias[dias]++;
        }
        vet.push(values[key]);
      }
      //console.log("vet",vet);
      //console.log("self.ultimosDias",self.ultimosDias);
    });

  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
