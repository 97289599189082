<template>
  <v-row>
    <v-col cols="12">
      <admintopo />
      <v-row>
        <v-col cols="12">
          <v-row class="my-0 py-0">
            <v-col cols="12" class="mt-0 pt-0">
              <v-tabs v-bg:B class="pt-1 px-2 mb-4 rounded" height="26" v-model="tab" background-color="transparent" :color="cor.active.P">
                <v-tab class="mt-0" style="min-width: 0px;">
                  <span class="text-nonecase f-raleway">
                    <i v-i:duo#gear#16 class="absolute top-0 lef-0 ms-n2"></i>
                    <span class="ms-3">AppIntegra</span>
                  </span>
                </v-tab>
                <v-tab class="mt-0" style="min-width: 0px;">
                  <span class="text-nonecase f-raleway">
                    <i v-i:duo#gear#16 class="absolute top-0 lef-0 ms-n2"></i>
                    <span class="ms-3">E2A</span>
                  </span>
                </v-tab>
                <v-tab class="mt-0" v-for="(item,index) in tabItems" :key="index" style="min-width: 0px;">
                  <span class="text-nonecase f-raleway">
                    <i v-if="index < 4" v-i:duo#users#16 class="absolute top-0 lef-0 ms-n2"></i>
                    <i v-if="index == 4" v-i:ic#upload#16 class="absolute top-0 lef-0 ms-n2"></i>
                    <i v-if="index == 5" v-i:ic#download#16 class="absolute top-0 lef-0 ms-n2"></i>
                    <span class="ms-3">{{ item }}</span>
                  </span>
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab" v-bg:b class="">
                <v-tab-item class="">
                  <appintegratabshome />
                </v-tab-item>
                <v-tab-item class="">
                  <e2atabshome />
                </v-tab-item>
                <v-tab-item>
                  <importacao />
                </v-tab-item>
                <v-tab-item>
                  <exportacao />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
//import colaboradores from "./colaboradores.vue"
import importacao from "./importacao/importacao.vue"
import admintopo from "./admin-topo.vue"
import exportacao from "./exportacao/exportacao.vue";
import appintegratabshome from "./tab-AppIntegra/tabs-home.vue";
import e2atabshome from "./e2a/tabs-home.vue"

export default {
  name: "usuariosview",
  components: { admintopo, appintegratabshome, e2atabshome, importacao, exportacao },
  data(){
    return {
      tab: null,
      tabItems: [ "Importar", "Exportar"],
      equipe: {},
    }
  },
  computed: {
  },
  watch: {
  },
  beforeMount() {
    var equipe = this.$store.state.admin.equipesByName["Administradores AppIntegra"];
    //console.log("============================== passei aqui");
    if(equipe.pessoas[this.userLog.id] == undefined) {
      this.$router.push({ name: 'Home'});
    }
  },
  mounted: function() {
    var self = this;
    this.log("usuariosview Mounted");

    rdb.ref('/usuarios/equipes/-Me1wkpw1Jby_ywz16iI').on('value', function(snapshot) {
      self.equipe = snapshot.val();
      //console.log("self.equipe",self.equipe);
    });

  },

  methods:{

    teste() {
      console.log("teste");
    }

  }
}
</script>

<style scoped>

</style>
