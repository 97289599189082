<template>
  <div class="my-1 flexb fjustify-start falign-center" v-if="user != null">
    <div class="px-1" style="">
      <v-avatar size="20">
        <v-img :src="user.urlFoto"></v-img>
      </v-avatar>
    </div>
    <div class="px-1 f-sanspro fs-8pt fw-600 mt-1">
      {{user.nome}}
    </div>
    <div class="px-1 text-right f-sanspro fs-10pt fw-600 mt-1">
      {{user.cpf}}
    </div>
    <editobject :obj="oferta" @onSave="onSave"/>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import editobject from "./edit-object.vue"

export default {
  name: "professoritem",
  components: { editobject },
  props: {
    userID: { default: "", type: String },
    oferta: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      user: null,
    }
  },
  watch: {
    userID() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    self.build();
  },
  methods:{

    build() {
      var self = this;
      //console.log("this.userID",this.userID);
      if(this.userID != "") {
        rdb.ref('/usuarios/pessoas/'+this.userID).on('value',function(snapshot) {
          self.user = snapshot.val();
          //console.log("self.user",self.user);
          //self.build();
        });
      }
    },

    onSave(obj) {
      this.$emit('onSave',obj);
    }

  }
}
</script>

<style scoped>
</style>
