<template>
  <v-row>
    <v-col>
      <p class="ms-2 mt-1 mb-0 f-sanspro fs-11pt fw-400">
        {{Object.keys(ofertas).length}} professores
      </p>
      <div class="" v-for="(oferta,key,index) in ofertas" :key="key">
        <professoritem :userID="oferta.profID" :oferta="oferta" :key="key+refresh"/>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import professoritem from "./professor-item.vue"

export default {
  name: "professores",
  components: { professoritem },
  props: {
    uc: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      ofertas: {},
    }
  },
  watch: {
    uc() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    self.build();
  },
  methods:{

    build() {
      var self = this;
      if(this.uc != null) {
        rdb.ref('/curriculo/ofertas/2021S2').orderByChild("ucID").equalTo(this.uc.id).on('value',function(snapshot) {
          var snap = snapshot.val();
          self.ofertas = {};
          if(snap != null) {
            self.ofertas = snap;
          }
          console.log("self.ofertas",self.ofertas);
          self.refresh++;
        });
      }

    }
  }
}
</script>

<style scoped>
</style>
