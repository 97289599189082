<template>
  <v-card class="py-2 px-3">
    <div class="">
      <p class="ms-0 my-0 f-sanspro fw-600 fs-10pt" v-cHex="hexShades(cor.active.B,0.5)">
        <i class="btn-icon-left" v-i:duo#users#16></i>
        <span class="fw-800">{{pessoasOnline.length}}</span>
        <span class="ms-1">Pessoas on-line</span>
        <span class="absolute ms-1" v-i:ic#circle#10 v-c:V></span>
      </p>
    </div>
    <div class="mt-1">
      <v-card class="p-1 my-1" outlined v-for="(user,index) in pessoasOnline" :key="index">
        <div class="my-1" style="height: 32px;">
          <div class="left">
            <avatarcon :user="user" size="34"/>
          </div>
          <div class="left ms-2 mt-0 text-truncate" style="max-width: 80%;">
            <p class="m-0 p-0 f-raleway fs-8pt fw-500 line-height-2 text-truncate">
              {{user.nome}}
            </p>
            <p class="m-0 p-0 f-lato fs-9pt fw-300 line-height-2 text-truncate">
              {{user.email}}
            </p>
            <p class="m-0 p-0 f-sanspro fs-8pt fw-200 line-height-3 text-truncate">
              <onlinesince :dbPathField="'/usuarios/pessoasLogControl/'+user.id"/>
            </p>
          </div>
        </div>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import avatarcon from "./avatar-con.vue";
import onlinesince from "./onlinesince.vue";
import moment from "moment";

export default {
  name: "cardacessos",
  components: { avatarcon, onlinesince },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      pessoasOnline: [],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    window.moment = moment;

    //self.loading = true;
    var agora = moment().subtract(1,"minutes").format("YYYY-MM-DDTHH:mm");
    rdb.ref('/usuarios/pessoasLogControl').orderByChild("last").startAfter(agora).on('value',function(snapshot) {
      var values = snapshot.val();
      //console.log("values",values);
      self.pessoasOnline = [];
      for(var key in values) {
        //console.log("key",key);
        if(values[key].logged) {
          rdb.ref('/usuarios/pessoas/'+key).once('value',function(snapshot) {
            var user = snapshot.val();
            if(user != null) {
              self.pessoasOnline.push(user);
            }
          });
        }
      }
      //console.log("self.pessoasOnline",self.pessoasOnline);
    });
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
