<template>
  <div class="" v-if="uc != null">
    <div class="mt-1">
      <div class="ms-3 me-2 flexb fjustify-start falign-center">
        <div class="flex1">
          <span class="f-sanspro fw-600">{{Object.keys(metas).length}}</span>
          <span class="ms-1 f-sanspro">metas</span>
        </div>
        <div class="flex0 border text-right">
          <editobjectbtn :obj="{ ucID: '' }" :key="uc.id" label="Mover metas para UC" @onSave="moverParaUC"/>
        </div>
      </div>
    </div>
    <div class="me-4 mt-2" v-if="uc != null">
      <div class="my-1 flexb fjustify-start falign-center" v-for="(meta,key,index) in metas" :key="key">
        <v-card class="mx-2 mb-1 p-1 f-raleway fs-10pt">
          {{meta.texto}}
        </v-card>
        <div class="">
          <editobject :obj="meta" @onSave="onSave" :key="meta.key"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import vueJsonEditor from 'vue-json-editor'
import editobject from "./edit-object.vue"
import editobjectbtn from "./edit-object-btn.vue"

export default {
  name: "",
  components: { vueJsonEditor, editobject, editobjectbtn },
  props: {
    uc: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      metas: {},
      ucIDToMove: "",
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;
    if(this.uc != null) {
      rdb.ref('/curriculo/metas').orderByChild("ucID").equalTo(this.uc.id).on('value',function(snapshot) {
        var snap = snapshot.val();
        self.metas = {};
        if(snap != null) {
          self.metas = snap;
        }
        console.log("self.metas",self.metas);
        self.build();
      });
    }
  },
  methods:{

    build() {
      var self = this;
    },

    onJsonChange (value) {
      console.log('value:', value)
    },

    onSave(obj) {
      obj = clone2(obj);
      console.log("salvar",obj);
      var updates = {};
      updates["/curriculo/metas/"+obj.id] = obj;
      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("Agora já elvis!!!!");

    },

    moverParaUC(obj) {
      obj = clone2(obj);
      console.log("moverParaUC",obj);
    }


  }
}
</script>

<style scoped>
</style>
