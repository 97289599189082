<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined class="mt-3">
        <p class="my-2 mx-2">
          <v-btn class="ms-2" color="" outlined x-small @click="exportarMeta">
            <i v-i:ic#download#16 class="btn-icon-left"></i>
            <span>Exportar TODAS metas de compreensão</span>
          </v-btn>
          <v-btn class="ms-2" color="" outlined x-small @click="exportarMetaA2">
            <i v-i:ic#download#16 class="btn-icon-left"></i>
            <span>Exportar metas de compreensão - Indicadas A2</span>
          </v-btn>
        </p>
        <hr class="my-2">
        <v-row>
          <v-col cols="4">
            <v-card class="my-2 mx-2 p-1">
              <p class="m-0 p-0">
                <span class="fw-800">{{Object.keys(metas).length}}</span> metas carregadas (appIntegra)
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <apploading :show="loading" />
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js';
import XLSX from 'xlsx';
import moment from 'moment';
import apploading from './appLoading.vue';
//import { ucsNE } from "./ucsne.js";
//import { ofertasPlan } from "./ofertas.js";

export default {
  name: "exportarementa",
  components: { apploading },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      workbook: null,
      reader: null,
      linhas: null,
      filename: [],
      ucs: {},
      ucsNome: {},
      ucsCodigo: {},
      metas: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;

    rdb.ref('/curriculo/ucs').on('value',function(snapshot) {
      self.ucs = snapshot.val();
      self.ucsNome = {};
      self.ucsCodigo = {};
      for(var idx in self.ucs) {
        var nome = self.ucs[idx].nome.trim().toUpperCase();
        var codigo = "";
        if(self.ucs[idx].codigo != undefined) {
          codigo = ""+parseInt((""+self.ucs[idx].codigo).trim().toUpperCase());
          self.ucsCodigo[codigo] = self.ucs[idx];
        }
        self.ucsNome[nome] = self.ucs[idx];
        var codigoLive = self.ucs[idx].codigoLive;
        if(codigoLive != undefined) {
          self.ucsCodigo[codigoLive] = self.ucs[idx];
        }
      }
      console.log("self.ucs",self.ucs);
      console.log("qtde self.ucs",Object.keys(self.ucs).length);
      console.log("self.ucsNome",self.ucsNome);
      console.log("qtde self.ucsNome",Object.keys(self.ucsNome).length);
      console.log("self.ucsCodigo",self.ucsCodigo);
      console.log("qtde self.ucsCodigo",Object.keys(self.ucsCodigo).length);
    });

    rdb.ref('/curriculo/metas').on('value',function(snapshot) {
      self.metas = snapshot.val();
      console.log("self.metas",self.metas);
      console.log("qtde self.metas",Object.keys(self.metas).length);
    });

  },
  methods:{

    build() {
      var self = this;
    },

    parseXLSX(abaIdx) {
      var arrayBuffer = this.reader.result;
      var options = { type: 'array' };
      var workbook = XLSX.read(arrayBuffer, options);
      console.timeEnd();
      var sheetName = workbook.SheetNames
      var sheet = workbook.Sheets[sheetName[abaIdx]]
      this.linhas = XLSX.utils.sheet_to_json(sheet, {raw: false});
      console.log(this.linhas);
      this.loading = false;
      this.refreshKey += 1;
      this.$forceUpdate();
      this.build();
    },

    parseExcelFile1(file) {
      var self = this;
      self.loading = true;
      console.log("parseExcelFile1",file);
      console.log(this.$refs.fileinputref);
      if(file == undefined) return;
      console.time();
      this.reader = new FileReader();
      this.reader.onloadend = function(event) {
        self.parseXLSX(0);
      };
      this.reader.readAsArrayBuffer(file);
    },

    parseAba(abaIdx) {
      this.parseXLSX(abaIdx)
      this.$forceUpdate();
    },

    build() {
      console.log("build");
      console.log(this.linhas);
      var self = this;

    },

    exportarMeta() {
      console.log("exportarMeta");

      //EXPORTAR OFERTAS BACKUP
      var vet = [];
      for(var idx in this.metas) {
        var metaObj = {};
        var meta = clone2(this.metas[idx]);
        if(this.ucs[meta.ucID] == undefined) {
          console.log("meta",meta);
        }
        var areas = {};
        var areaTexto = "";

        metaObj.areas = areaTexto;
        metaObj.ucCOD = "";
        metaObj.ucSGL = "";
        metaObj.ucNome = "";
        if(this.ucs[meta.ucID] != undefined) {
          metaObj.ucCOD = this.ucs[meta.ucID].ucCOD;
          metaObj.ucSGL = this.ucs[meta.ucID].ucSGL;
          metaObj.ucNome = this.ucs[meta.ucID].nome;
          areas = this.ucs[meta.ucID].areas;
          for(var idx2 in areas) {
            areaTexto = areaTexto + areas[idx2].sigla + ";"
          }
        }
        metaObj.areas = areaTexto;
        metaObj.ucID = meta.ucID;
        metaObj.metaCod = meta.metaCodigo;
        metaObj.id = meta.id;
        metaObj.texto = meta.texto;
        if(meta.meta != undefined && meta.texto == undefined) {
          metaObj.texto = meta.meta;
        }
        vet.push(metaObj);
      }

      console.log("vet",vet);
      const fileName = "appIntegra-METAS-"+moment().format()+".xlsx";
      const ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
      const wb = XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "appIntegra-METAS");
      XLSX.writeFile(wb, fileName);

    },

    exportarMetaA2() {
      console.log("exportarMetaA2");
      var self = this;

      rdb.ref('/curriculo/avaliacao/2021S2/metasIndicadasFinal').once('value',function(snapshot) {
        var metas = snapshot.val();
        console.log("metas "+Object.keys(metas).length,metas);

        var vet = [];
        for(var idx in metas) {
          var metaObj = {};
          var meta = clone2(metas[idx]);
          delete meta.createdAt;
          delete meta.emProducao;
          delete meta.qtdeVotos;
          if(meta.texto == undefined) {
            meta.texto = meta.meta;
          }
          delete meta.meta;
          delete meta.questoes;
          delete meta.votos;
          delete meta.coments;
          delete meta.likes;
          delete meta.area;
          delete meta.areaID;
          delete meta.ativada;
          delete meta.conteudos;
          delete meta.nova;
          delete meta.acheiID;
          delete meta.acheiD;
          delete meta.metaCodigosRepetidos;
          delete meta.achei;
          var uc = self.ucs[meta.ucID];
          if(uc == undefined) {
            console.log("UC não encontrada: ",meta);
          } else {
            meta.ucSGL = "";
            if(uc.codigo != undefined) {
              meta.ucSGL = uc.codigo;
            }
            if(uc.ucSGL != undefined) {
              meta.ucSGL = uc.ucSGL;
            }
            meta.ucCOD = "";
            if(uc.ucCOD != undefined) {
              meta.ucCOD = uc.ucCOD;
            }
            vet.push(meta);
          }
        }

        console.log("vet",vet);
        const fileName = "appIntegra-METAS-A2"+moment().format()+".xlsx";
        const ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
        const wb = XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "appIntegra-METAS-A2");
        XLSX.writeFile(wb, fileName);

      });

    }

  }
}
</script>

<style scoped>
</style>
