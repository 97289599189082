import { render, staticRenderFns } from "./card-acessos.vue?vue&type=template&id=8078c104&scoped=true&"
import script from "./card-acessos.vue?vue&type=script&lang=js&"
export * from "./card-acessos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8078c104",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
installComponents(component, {VBtn,VCard,VCol,VRow,VSheet,VSparkline})
