<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card class="pb-2">
      <v-card-title class="fs-13pt my-0 pb-1" v-c:I>
        <span class="fw-700 f-raleway">{{btnName}}</span>
        <span class="ps-1 fw-300 f-raleway">usuário</span>
      </v-card-title>
      <v-divider class="mt-0 mb-1 py-0"></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12 my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto mt-0 mb-n2 pb-0 ps-1">Foto do usuário</p>
              <v-row>
                <v-col cols="12" lg="2">
                  <v-avatar size="56">
                    <img :src="formData.urlFoto" class="rounded-circle" alt="" style="">
                  </v-avatar>
                </v-col>
                <v-col cols="12" lg="10" class="my-0 py-0">
                  <v-text-field solo dense class="mb-n4 mt-3" v-model="formData.urlFoto" type="text" placeholder="url / link"></v-text-field>
                  <span class="ms-1 my-0 py-0 fs-7">
                    Proporção (largura/altura): 1 x 1
                  </span>
                  <fileupload class="ms-1" path="responsaveis" refreshModel="urlFoto" @urlUpload="urlUpload">Upload</fileupload>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12 my-3 py-0">
              <p class="fw-300 fs-10pt f-roboto mt-2 mb-n2 pb-0 ps-1">Foto de capa do usuário</p>
              <v-row>
                <v-col cols="12" lg="4">
                  <img :src="formData.urlCapa" class="" alt="" style="width: 100%; max-height: 80px;">
                </v-col>
                <v-col cols="12" lg="8" class="my-0 py-0">
                  <v-text-field solo dense class="mb-n4 mt-3" v-model="formData.urlCapa" type="text" placeholder="url / link"></v-text-field>
                  <span class="ms-1 my-0 py-0 fs-7">
                    Proporção (largura/altura): 12 x 1
                  </span>
                  <fileupload class="ms-1" path="responsaveis" refreshModel="urlCapa" @urlUpload="urlUpload">Upload</fileupload>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-2 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Nome do usuário</p>
              <v-text-field v-model="formData.nome" :rules="[rules.required]" solo dense label="Nome" required placeholder="Digite o nome"></v-text-field>
            </v-col>
            <v-col cols="12" lg="12" class="mt-0 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Email do usuário</p>
              <v-text-field v-model="formData.email" :rules="[rules.required, rules.email]" solo dense label="Email" required placeholder="Digite o email"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="mt-0 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Telefone do usuário</p>
              <v-text-field v-model="formData.telefone" solo dense label="Telefone" required placeholder="Digite o telefone"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="mt-0 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">CPF do usuário</p>
              <v-text-field v-model="formData.cpf" solo dense label="CPF" required placeholder="Digite o cpf"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="mt-n2 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Áreas VPA</p>
              <treeselect v-if="areas.length>0" v-model="setorSel" multiple :normalizer="setorNormalizer" :options="setores" placeholder="Filtrar por área">
                <div slot="option-label" slot-scope="{ node }">
                  <span class="fs-10pt fw-300 truncate">{{node.raw.nome}}</span>
                </div>
                <div slot="value-label" slot-scope="{ node }">
                  <span v-c:P class="fs-7pt fw-300 truncate">{{node.raw.nome.substr(0,10)}}</span>
                </div>
              </treeselect>
              <!-- <VueObjectView v-model="setorSel" :key="refreshKey"/> -->
            </v-col>
            <v-col cols="12" lg="6" class="mt-n2 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Equipes</p>
              <treeselect v-if="areas.length>0" v-model="equipeSel" multiple :normalizer="equipeNormalizer" :options="equipes" placeholder="Filtrar por área">
                <div slot="option-label" slot-scope="{ node }">
                  <span class="fs-10pt fw-300 truncate">{{node.raw.nome}}</span>
                </div>
                <div slot="value-label" slot-scope="{ node }">
                  <span v-c:P class="fs-7pt fw-300 truncate">{{node.raw.nome.substr(0,10)}}</span>
                </div>
              </treeselect>
              <!-- <VueObjectView v-model="equipeSel" :key="refreshKey"/> -->
            </v-col>
            <v-col cols="12" lg="8" class="mt-4 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Áreas de Integração Curricular</p>
              <treeselect v-if="areas.length>0" v-model="areasSel" multiple :normalizer="areaNormalizer" :options="areas" placeholder="Filtrar por área">
                <div slot="option-label" slot-scope="{ node }">
                  <span class="fs-10pt fw-300 truncate">{{node.raw.nome}}</span>
                </div>
                <div slot="value-label" slot-scope="{ node }">
                  <span v-c:P class="fs-7pt fw-300 truncate">{{node.raw.nome.substr(0,10)}}</span>
                </div>
              </treeselect>
            </v-col>
            <v-col cols="12" lg="4" class="mt-3 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Grande área</p>
              <v-select solo dense v-model="formData.grandeArea" :items="['0','1', '2', '3']"></v-select>
            </v-col>
            <v-col cols="12" lg="4" class="mt-3 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Cargo</p>
              <v-select solo dense v-model="formData.cargo" :items="itensCargos"></v-select>
            </v-col>
            <v-col cols="12" lg="4" class="mt-3 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Formação</p>
              <v-select solo dense v-model="formData.formacao.titulacao" :items="itensFormacao"></v-select>
            </v-col>
            <v-col cols="12" lg="4" class="mt-2 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Área de Formação</p>
              <v-text-field v-model="formData.formacao.area" solo dense label="Área de formação acadêmica" placeholder="Digite área de formação acadêmica"></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" class="mt-n2 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Unidade</p>
              <v-select solo dense v-model="formData.unidade" :items="itensUnidade"></v-select>
            </v-col>
            <v-col cols="12" lg="4" class="mt-n2 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">Regional</p>
              <v-select solo dense v-model="formData.regional" :items="['SP','MG / GO', 'NE', 'SUL']"></v-select>
            </v-col>
            <v-col cols="12" lg="4" class="mt-n2 mb-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pt-0 pb-1 ps-1">IEs</p>
              <v-select solo dense v-model="formData.ies" :items="itensIES"></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn :color="cor.active.S" small @click="dialog = false">
          cancelar
        </v-btn>
        <v-btn :color="cor.active.V" v-c:B small @click="update">
          <i class="btn-icon-left" v-i:duo#check-circle#18></i>
          {{btnName}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import fileupload from './fileupload'
import VueObjectView from 'vue-object-view';

export default {
  name: "formusuario",
  components: { fileupload, VueObjectView },
  data() {
    return {
      dialog: false,
      loading: false,
      refreshKey: 0,
      btnName: "Adicionar",
      areas: [],
      areasObj: [],
      areasSel: {},
      setores: [],
      setoresObj: {},
      setorSel: [],
      equipes: [],
      equipesObj: {},
      equipeSel: [],
      itensCargos: [ 'Educador(a)', 'Professor(a)', "Gestor(a) de Área", 'Assessor(a) de Área', 'Coordenador(a) Regional', 'Coordenador(a) de Área', 'Coordenador(a) de Cursos' ],
      itensFormacao: ['Graduado', 'Especialista', 'Mestre', 'Doutor(a)', 'Pós Doutor(a)'],
      itensUnidade: [
        "Aimorés","Anita Garibaldi","Barreiro","Betim","Blumenau","Bom Despacho","Butantã","Catalão",
        "Conselheiro Lafaiete","Contagem","Cristiano Machado","Divinópolis","Florianópolis","Guanambi","Guarulhos","Itabira","Itumbiara","Jabaquara","Jacobina","Jaraguá","Jataí","Lagarto","Liberdade","Linha Verde","Milton Viana","Mooca","Paripiranga","Paulista","Pedra Branca","Pouso Alegre","Santana","Santo Amaro","São Bento do Sul",
        "São Bernardo do Campo","Senhor do Bomfim","Sete Lagoas","Tubarão","Uberlândia","Vila Leopoldina","Vila Matias"
      ],
      itensIES: [ "AGES","UNA","UNIBH","UNICURITIBA","USJT","UNISOCIESC","UNISUL","UNIFG"],
      formData: {
        nome: "", cargo: "Educador(a)", urlCapa: "https://placeimg.com/1200/200/nature", urlFoto: "https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/responsaveis%2Fdummy.png?alt=media&token=f785c7d3-f03c-4311-acec-983e2f9e9103",
        formacao: { area: "", titulacao: "Graduado" }, grandeArea: 0, ies: "USJT", regional: "SP",
        unidade: "", email: "", telefone: "", cpf: "", senha: "xxxx", admin: false, equipes: {}, setores: {}
      },
      rules: {
        required: value => !!value || 'Obrigatório',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  computed: {
  },
  watch: {
    equipeSel() {
      this.refreshKey++;
    },
    setorSel() {
      this.refreshKey++;
    }
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    rdb.ref('/curriculo/areas').on('value', function(snapshot) {
      self.areas = snapshotValToArray(snapshot.val());
      self.areasObj = snapshot.val();
      console.log(self.areas);
    });
    rdb.ref('/usuarios/setores').on('value', function(snapshot) {
      self.setores = snapshotValToArray(snapshot.val());
      self.setoresObj = snapshot.val();
      console.log(self.setores);
    });
    rdb.ref('/usuarios/equipes').on('value', function(snapshot) {
      self.equipes = snapshotValToArray(snapshot.val());
      self.equipesObj = snapshot.val();
      console.log(self.equipes);
    });
    self.loading = false;
  },

  methods:{

    areaNormalizer(node) { return { id: node.id, label: node.nome } },
    setorNormalizer(node) { return { id: node.id, label: node.nome } },
    equipeNormalizer(node) { return { id: node.id, label: node.nome } },

    urlUpload(urlLink,prop) {
      console.log(urlLink);
      console.log(prop);
      this.formData[prop] = urlLink;
      this.$forceUpdate();
    },

    show(dados) {
      console.log("dados",dados);
      this.dialog = true;
      if(dados == null) {
        this.formData = {
          nome: "", cargo: "Educador(a)", urlCapa: "https://placeimg.com/1200/200/nature", urlFoto: "https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/responsaveis%2Fdummy.png?alt=media&token=f785c7d3-f03c-4311-acec-983e2f9e9103",
          formacao: { area: "", titulacao: "Graduado" }, grandeArea: 0, ies: "USJT", regional: "SP",
          unidade: "", email: "", telefone: "", cpf:"", senha: "xxxx", admin: false,
        };
        this.areasSel = [];
        this.setorSel = [];
        this.equipeSel = [];
        this.btnName = "Adicionar";
      } else {
        this.formData = clone2(dados);
        this.areasSel = [];
        for(var key in this.formData.areasIC) {
          if(this.areasObj[key] != undefined) {
            this.areasSel.push(key);
          } else {
            for(var key2 in this.areasObj) {
              if(this.areasObj[key2].sigla == this.formData.areasIC[key].sigla) {
                this.areasSel.push(key2);
              }
            }
          }
        }
        console.log("this.areasSel",this.areasSel);
        this.setorSel = [];
        for(var key in this.formData.setores) {
          if(this.setoresObj[key] != undefined) {
            this.setorSel.push(key);
          }
        }
        console.log("this.setorSel",this.setorSel);
        this.equipeSel = [];
        for(var key in this.formData.equipes) {
          if(this.equipesObj[key] != undefined) {
            this.equipeSel.push(key);
          }
        }
        console.log("this.equipeSel",this.equipeSel);
        this.btnName = "Atualizar";
      }
      console.log("this.formData",this.formData);
    },

    getEquipesSelecionadas() {
      var equipesSel = {};
      for(var pos in this.equipeSel) {
        var equipe = clone(this.equipesObj[this.equipeSel[pos]]);
        delete equipe.pessoas;
        equipesSel[equipe.id] = equipe;
      }
      return equipesSel;
    },

    getSetoresSelecionados() {
      var setoresSel = {};
      for(var pos in this.setorSel) {
        var setor = clone(this.setoresObj[this.setorSel[pos]]);
        setoresSel[setor.id] = setor;
      }
      return setoresSel;
    },

    getAreasICSelecionadas() {
      var areasSel = {};
      for(var pos in this.areasSel) {
        var area = clone(this.areasObj[this.areasSel[pos]]);
        areasSel[area.id] = { id: area.id, sigla: area.sigla };
      }
      return areasSel;
    },

    update() {
      if(this.formData.nome == "") {
        this.toastD("Nome obrigatório!");
        return;
      }
      if(this.formData.email == "") {
        this.toastD("Email obrigatório!");
        return;
      }
      this.formData.equipes = this.getEquipesSelecionadas();
      this.formData.setores = this.getSetoresSelecionados();
      this.formData.areasIC = this.getAreasICSelecionadas();
      this.dialog = false;
      this.$emit("update",{ formData: this.formData, tipo: this.btnName } );
    }

  }
}
</script>

<style scoped>

</style>
