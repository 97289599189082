<template>
  <v-row class="mt-1">
    <v-col cols="12">
      <v-card>
        <v-tabs class="" height="26" v-model="tab" background-color="transparent" :color="cor.active.P">
          <v-tab class="mt-0" v-for="(item,index) in ['ofertas SGD', 'Atualizar UCs Ofertadas']" :key="index" style="min-width: 0px;">
            <span class="text-nonecase f-raleway">
              <i v-if="index == 0" v-i:ic#upload#16 class="absolute top-0 lef-0 ms-n2"></i>
              <i v-if="index == 1" v-i:ic#refresh#16 class="absolute top-0 lef-0 ms-n2"></i>
              <span class="ms-3">{{ item }}</span>
            </span>
          </v-tab>
          <v-tab-item>
            <ofertassgd />
          </v-tab-item>
          <v-tab-item>
            <atualizarucs />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import ofertassgd from "./ofertas-sgd.vue";
import atualizarucs from "./atualizar-ucs.vue";

export default {
  name: "importacao",
  components: { ofertassgd, atualizarucs },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      tab: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
