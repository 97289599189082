<template>
  <div class="my-0" style="">
    <treeselect class="left" style="max-width: 85%" :load-options="loadOptions" v-model="selecao" :multiple="true" :options="options" placeholder="Filtrar">
    </treeselect>
    <v-btn class="mt-1 ms-2" :color="cor.active.I" v-bg:S icon small @click="filtrar">
      <i class="btn-icon-left ps-2 pt-1" v-i:ic#search#20></i>
    </v-btn>
    <!-- <VueObjectView class="clear" v-model="selecao" :key="refreshKey" /> -->
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import VueObjectView from 'vue-object-view';
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import { cargos } from "./cargos.js"

export default {
  name: "filtro",
  components: { VueObjectView },
  data(){
    return {
      loading: false,
      selecao: [],
      refreshKey: 0,
      options: [
        { id: 'areas', label: 'Áreas', children: null },
        { id: 'equipes', label: 'Equipes', children: null },
        { id: 'cargos', label: 'Cargos', children: cargos },
      ],
    }
  },
  computed: {
  },
  watch: {
    selecao() {
      this.refreshKey++;
    }
  },
  mounted: function() {
    var self = this;
    self.log("filtroarea Mounted");
    //self.loading = true;
  },

  methods:{

    loadOptions({ action, parentNode, callback }) {
      var self = this;
      if (action === LOAD_CHILDREN_OPTIONS) {
        if(parentNode.id == "areas") {
          rdb.ref('/usuarios/setores').on('value', function(snapshot) {
            var vet = snapshotValToArray(snapshot.val());
            console.log("vet",vet);
            parentNode.children = [];
            for(var key in vet) {
              parentNode.children.push({ id: "area:"+vet[key].id, label: vet[key].nome });
            }
            callback();
          });
        }
        if(parentNode.id == "equipes") {
          rdb.ref('/usuarios/equipes').on('value', function(snapshot) {
            var vet = snapshotValToArray(snapshot.val());
            console.log("vet",vet);
            parentNode.children = [];
            for(var key in vet) {
              parentNode.children.push({ id: "equipe:"+vet[key].id, label: vet[key].nome });
            }
            callback();
          });
        }
      }
    },

    normalizer(node) {
      return {
        id: node.id,
        label: node.nome,
      }
    },

    filtrar() {
      var self = this;
      console.log("filtrar");
      this.$emit("changesel",this.selecao);
    },

  }
}
</script>

<style scoped>

</style>
