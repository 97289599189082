<template>
  <v-row class="">
    <v-col cols="12" md="6">
      <v-text-field class="my-0 pb-0" v-model="filtro" dense solo></v-text-field>
      <v-card class="mt-n4 p-1 card-scroll" style="height: 400px; max-height: 400px">
        <div class="m-0 p-0" v-for="(uc,key,index) in ucsFiltradas" :key="key">
          <ucitem v-if="index < 50" :uc="uc" :area="area" @ucClick="ucClick" @onSave="onSave"/>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card class="p-1">
        <v-alert type="info" class="my-1 p-2" v-if="ucSel == null">
          <p class="m-0 p-0">UC não selecionada</p>
        </v-alert>
        <ucitem v-if="ucSel != null" :uc="ucSel" :area="area" :crud="false"/>
        <v-tabs class="pt-0 px-2 mb-4 rounded" height="23" v-model="tab" :background-color="hexTints(cor.active.b,.4)" :color="cor.active.P">
          <v-tab class="mt-0 fs-10pt" style="min-width: 0px;">
            <span class="text-nonecase f-raleway">
              <span class="ms-3">Metas</span>
            </span>
          </v-tab>
          <v-tab class="mt-0 fs-10pt" style="min-width: 0px;">
            <span class="text-nonecase f-raleway">
              <span class="ms-3">Ofertas</span>
            </span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" v-bg:b class="mt-n1 mx-2">

          <v-tab-item>
            <metas :uc="ucSel" :key="ucSel.id" v-if="ucSel != null"/>
          </v-tab-item>

          <v-tab-item>
            <professores :uc="ucSel" :key="ucSel.id" v-if="ucSel != null"/>
          </v-tab-item>

        </v-tabs-items>

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import ucitem from "./uc-item.vue";
import metas from "./metas.vue";
import tagarea from "@/views/cursos/tagArea.vue";
import professores from "./professores.vue"

export default {
  name: "ucs",
  components: { ucitem, tagarea, metas, professores },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      tab: null,
      ucs: {},
      ucsFiltradas: {},
      area: { cor: "#000000" },
      options: { confirmText: 'confirm', cancelText: 'cancel' },
      ucSel: null,
      filtro: "",
    }
  },
  watch: {
    filtro() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    self.loading = true;
    rdb.ref('/curriculo/ucs').on('value',function(snapshot) {
      self.ucs = snapshot.val();
      console.log("self.ucs",self.ucs);
      self.build();
    });
  },
  methods:{

    build() {
      var self = this;
      console.log(this.filtro);
      if(this.filtro.trim() == "") {
        self.ucsFiltradas = self.ucs;
      } else {
        self.ucsFiltradas = {};
        for(var key in self.ucs) {
          if(self.ucs[key].nome.toUpperCase().indexOf(self.filtro.trim().toUpperCase()) != -1) {
            self.ucsFiltradas[key] = self.ucs[key];
          }
        }
      }
    },

    ucClick(uc) {
      console.log("ucClick",uc);
      this.ucSel = uc;
      this.$forceUpdate();
      this.$nextTick(function() {
        if(this.$refs.jsoneditor != undefined) {
          this.$refs.jsoneditor.collapseAll();
        }
      });
    },

    onSave(obj) {
      obj = clone2(obj);
      console.log("salvar",obj);
      var updates = {};
      updates["/curriculo/ucs/"+obj.id] = obj;
      console.log("updates",updates);
      rdb.ref().update(updates);
      console.log("Agora já elvis!!!!");

    },

  }
}
</script>

<style scoped>
</style>
